.container {
  border-bottom: 1px solid var(--color-divider);
  padding: var(--section-spacing) 0;
}

.title {
  margin-bottom: var(--spacing-20);
  padding: 0 var(--page-inset);
}

div.slide {
  width: 70%;

  &:first-child {
    margin-left: var(--page-inset);
  }
  &:last-child {
    margin-right: var(--page-inset);
  }
}
