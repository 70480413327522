div.search {
  padding: 0;
  background: var(--color-white);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  gap: 0;

  transform: unset !important;
  clip-path: inset(0 0 100% 0);

  &[data-open="true"] {
    clip-path: inset(0 0 0 0);
  }

  &:not([data-open="true"]) {
    clip-path: inset(0 0 100% 0);
  }

  @media (--small) {
    height: 80vh;

    overflow: hidden;
  }

  @extend %typo-body1;
}
